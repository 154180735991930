export interface Resource {
  ext: string;
  id: string;
  media_format: string;
  name: string;
  path: string;
  size: number;
  type: string;
}

export interface MagnetResource {
  link: string;
  name: string;
}

export default async function fetchStreamingLinkFromMagnet(
  magnet: string,
): Promise<MagnetResource> {
  const responseStoreResource = await fetch(
    "https://webtor.p.rapidapi.com/resource",
    {
      method: "POST",
      headers: {
        "content-type": "text/plain",
        "X-RapidAPI-Key": import.meta.env.VITE_X_RapidAPI_Key,
        "X-RapidAPI-Host": "webtor.p.rapidapi.com",
      },
      body: magnet,
    },
  );
  const resource = await responseStoreResource.json();

  const responseListResource = await fetch(
    `https://webtor.p.rapidapi.com/resource/${resource.id}/list?path=%2F&limit=10&offset=0`,
    {
      method: "GET",
      headers: {
        "X-RapidAPI-Key": import.meta.env.VITE_X_RapidAPI_Key,
        "X-RapidAPI-Host": "webtor.p.rapidapi.com",
      },
    },
  );
  const resourceList = await responseListResource.json();
  resourceList.items.sort((a: Resource, b: Resource) => {
    if (a.size > b.size) return -1;
    if (b.size > a.size) return 1;
    return 0;
  });

  const responseExportResource = await fetch(
    `https://webtor.p.rapidapi.com/resource/${resource.id}/export/${resourceList.items[0].id}`,
    {
      method: "GET",
      headers: {
        "X-RapidAPI-Key": import.meta.env.VITE_X_RapidAPI_Key,
        "X-RapidAPI-Host": "webtor.p.rapidapi.com",
      },
    },
  );
  const resourceExport = await responseExportResource.json();
  const file = resourceExport.source as Resource;

  return { link: resourceExport.exports.stream.url, name: file.name };
}
