export interface Cookie {
  name: string;
  value: string;
}

export function getCookies(): Cookie[] {
  const cookiesStr = document.cookie;
  const cookiesArr = cookiesStr.split(";");
  const cookies = cookiesArr.map((cookie) => {
    const cookieParts = cookie.split("=");
    if (cookieParts.length < 2) {
      return { name: "", value: "" };
    }
    return { name: cookieParts[0].trim(), value: cookieParts[1].trim() };
  });

  return cookies;
}
